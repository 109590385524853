import { inject, Injectable } from '@angular/core';

import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class SplashScreenGuard {
	private _document = inject<Document>(DOCUMENT);

	canActivate() {
		this.hide();
		return true;
	}

	hide() {
		// this._document.body.classList.add('agdir-splash-screen-hidden');
		const splashScreen = this._document.querySelector('agdir-splash-screen');
		if (splashScreen) {
			splashScreen.classList.add('off');
			setTimeout(() => {
				splashScreen.parentElement?.removeChild(splashScreen);
			}, 600);
		}
	}

	showMessage(message: string) {
		const place = this._document.body.querySelector('#agdir-splash-screen-message');
		if (place) {
			place.innerHTML = message;
		}
	}
}
